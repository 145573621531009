import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';

export const exportToXLSX = async ({ tableName, tableData, params }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Championship');

    console.log('tableData321: ', tableData)
    console.log('params: ', params);

    worksheet.addRows(tableData);
    /** Set the first cell with championship name to be big */
    // worksheet.mergeCells('A1:C3');
  
    worksheet.getCell('A1').font = { size: 18, bold: true };
    worksheet.getCell('A1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
  
    worksheet.getColumn('A').width = 5;
    worksheet.getColumn('B').width = 40;
    // worksheet.getColumn('C').width = 40;
  
    worksheet.getRow(4).height = 50;
  
    /** Set default cell params */
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });
  
    if (params.columns) {
      for (let i = 0; i < params.columns.length; i++) {
        const columnParams = params?.columns[i];
        const column = worksheet.columns[i];
        if (!columnParams || !column) continue;
  
        if (columnParams.width) {
          column.width = columnParams.width;
        }
  
        const cellsList = columnParams.cells || [];
  
        if (true) {
          const exceptIndices = columnParams['$except'] || [];
          column.eachCell({ includeEmpty: true }, (cell, index) => {
            if (columnParams.left !== true) {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
  
            if (columnParams.allCells && !exceptIndices.includes(index)) {
              if (columnParams?.allCells?.border) {
                cell.border = {
                  ...cell.border,
                  ...columnParams.allCells.border,
                };
              }
  
              if (columnParams?.allCells?.alignment) {
                cell.alignment = {
                  ...cell.alignment,
                  ...columnParams.allCells.alignment,
                };
              }
  
              if (columnParams?.allCells?.font) {
                cell.font = { ...cell.font, ...columnParams.allCells.font };
              }
  
              if (columnParams?.allCells?.fill) {
                cell.fill = { ...cell.fill, ...columnParams.allCells.fill };
              }
            }
  
            const cellData = cellsList[index - 1];
            if (cellData) {
              if (cellData.alignment) {
                cell.alignment = { ...cell.alignment, ...cellData.alignment };
              }
  
              if (cellData.border) {
                cell.border = { ...cell.border, ...cellData.border };
              }
  
              if (cellData.font) {
                cell.font = { ...cell.font, ...cellData.font };
              }
            }
          });
        }
      }
    }
  
    worksheet.getRow(4).eachCell((cell) => {
      cell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center',
      };
      cell.font = { bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFA4C1E2' },
      };
    });
  
    // worksheet.getRow(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA4C1E2' } };
  
    if (params.merges) {
      console.log(params.merges);
      params.merges.forEach((array) => {
        try {
          worksheet.mergeCells(array);
        } catch (error) {
          console.log("Cell merge error: ", error);
        }
      });
    }
  
    console.log(workbook);
  
    const fileBuffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([fileBuffer], { type: fileType });
    FileSaver.saveAs(data, tableName + fileExtension);
    console.log('Saved file');
  };