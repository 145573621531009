import { exportToXLSX } from './exportToXLSX';
import {
  loadChampData,
  loadChampStages,
  loadChampUsers,
  loadStageRoles,
  loadBattlesList,
  loadCompetencies,
  loadBattleInfo,
  loadBattleGrades
} from './exportApiCalls';
import { usePickerState } from '@material-ui/pickers';

const calculateAverageGrades = (experts, countOfCriterias) => {
  const totalGrades = experts.reduce((sum, expert) => {
    return sum + expert.result;
  }, 0);

  const totalExperts = experts.length;
  return totalGrades / totalExperts;
};

export default async function ExportChampionship(
  championshipId,
  stagesToAdd = [],
  params = {},
) {
  const id = championshipId;

  const champData = await loadChampData(id);
  const champTitle = champData.title;
  const stages = await loadChampStages(id);
  const users = await loadChampUsers(id);

  const exportUsersTable = async () => {  
    let participants = [];
    let stagesRoles = [];
    let battles = [];
    let competencies = [];
    let rolesWeights = [];
    let allGradesLimit = [];

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];

      if (!stagesToAdd.includes(stage.id)) {
        continue;
      }

      const stageBattles = await loadBattlesList(stage.id);
      let allRoles = await loadStageRoles(stage.id);
      let roles = [];
      let gradeRoles = [];

      //TODO: fix roles
      // participants.push([]);
      for (let j = 0; j < stageBattles.length; j++) {
        const userBattle = stageBattles[j];
        for (let n = 0; n < users.length; n++) {
          const user = users[n];
          if (userBattle.player_id === user.id) {
            participants.push(user);
          }
        }
        let battleGrades = [];
        if (userBattle) {
          battleGrades = await loadBattleGrades(userBattle.id);
        }
        for (let k = 0; k < battleGrades.length; k++) {
          const grade = battleGrades[k];
          const gradeUserID = grade.user_id;
          for (let l = 0; l < userBattle.roles.length; l++) {
            const role = userBattle.roles[l];
            gradeRoles.push(role.title);
            // for (let m = 0; m < role.user_info.length; m++) {
              
              // const role_info = role.user_info[m];
              // if (role_info.id === gradeUserID) {
                // gradeRoles.push(role.title);
              // }
            // }
          }
        }
        gradeRoles = [...new Set(gradeRoles)];
      }
      for (let j = 0; j < allRoles.length; j++) {
        const role = allRoles[j];
        for (let k = 0; k < gradeRoles.length; k++) {
          const gradeRole = gradeRoles[k];
          if (role.title === gradeRole) {
            roles.push(role);
          }
        }
      }

      const localUsers = users.filter((user) => user.is_player);
      /**
       * Clone identical roles.
       * For example, create two Inner Expert roles for two inner experts that has identical role
       * */

      const resultArr = [];
      if (stageBattles.length > 0) {
        const tempArrays = [];
        for (let l = 0; l < stageBattles.length; l++) {
          const battleData = stageBattles[l];
          const tempArray = roles.map((role) => ({ ...role }));
          for (let j = 0; j < roles.length; j++) {
            const role_id = roles[j].id;
            const battleRoles = battleData.roles.find(
              (role) => role.stage_roles_id === role_id,
            );
            if (battleRoles) {
              const battleUsersNum = battleRoles.user_info.length;
              for (let k = 1; k < battleUsersNum; k++) {
                tempArray.push({ ...roles[j], index: k });
              }
            }
          }
          tempArrays.push(tempArray);
        }

        // Объединение временных массивов в результирующий массив
        for (let i = 0; i < tempArrays.length; i++) {
          const tempArray = tempArrays[i];
          for (let item of tempArray) {
            if (
              !resultArr.some(
                (role) => role.id === item.id && role.index === item.index,
              )
            ) {
              resultArr.push(item);
            }
          }
        }
        resultArr.sort((a, b) => a.title.localeCompare(b.title));
        roles = resultArr;
      }

      const [competenciesList, stageRolesWeights, gradesLimit] =
        await loadCompetencies(stage.id);
      stagesRoles[i] = roles;
      battles[i] = stageBattles;
      competencies[i] = competenciesList;
      rolesWeights[i] = stageRolesWeights;
      allGradesLimit[i] = gradesLimit;
    }

    console.log(`Champ data is `, champData);
    console.log(`Stages are `, stages);
    console.log(`Users are `, users);
    console.log(`Stages roles are `, stagesRoles);
    console.log(`Battles are `, battles);
    console.log(`Competencies are `, competencies);
    console.log(`Roles weights are `, rolesWeights);
    console.log(`Grades limits are `, allGradesLimit);

    const tableData = [
      [`${champTitle}`, ''],
      ['', ''],
      ['', ''],
      /** Table head */
      [],
      /** Table body with users below */
    ];

    const titleMergeData = [1, 1, 3, 0];

    const tableHead = ['№', 'ФИО сотрудника'];

    const tableParams = {
      columns: [],
      merges: [],
    };

    if (params.rating_manager) {
      tableHead.push('Руководитель');
      for (let i = 0; i < 3; i++) {
        tableData[i].push('');
      }
      tableParams.columns[tableHead.length - 1] = {
        left: true,
        width: 40,
      };
    }

    if (params.rating_region) {
      tableHead.push('Регион');
      for (let i = 0; i < 3; i++) {
        tableData[i].push('');
      }
      tableParams.columns[tableHead.length - 1] = {
        left: true,
        width: 30,
      };
    }

    titleMergeData[3] = tableHead.length;

    const tableBody = [];

    tableData[3] = tableHead;

    tableParams.merges.push(titleMergeData);

    /**
     * An array with user data
     * Every user has ID and array of stages
     * Every stage has a title, id, roles
     * Every role for the stage has a user name, grades and result value
     */
    let usersDataInfo = [];

    let stagesAdded = 0;

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];

      if (!stagesToAdd.includes(stage.id)) {
        continue;
      }

      const stageRoles = stagesRoles[i];
      const stageBattles = battles[i];
      /** Weights object */
      // const stageRoleWeights = rolesWeights[i] || { inner: 0, outer: 0 };
      const stageRoleWeights = rolesWeights[i];
      const stageMergeData = [1, 0, 1, 0];
      const stageWeight = stage.weight || 0;
      const minGradeLimit = allGradesLimit[i]?.min - 1 || 0;
      const maxGradeLimit = allGradesLimit[i]?.max || 5;

      let shouldAddIntermediateColumn = stagesAdded !== 0;

      let shouldAddRatingDiffColumn = false;
      let shouldAddDynamicColumn = stagesAdded !== 0;

      if (!params.rating_diff) {
        shouldAddRatingDiffColumn = false;
      } else {
        shouldAddRatingDiffColumn = true;
      }

      if (!params.rating_dynamic) {
        shouldAddDynamicColumn = false;
      }

      /** Stage title */
      tableData[0].push(`${stage.title} (${(stageWeight * 100).toFixed(2)}%)`);
      /** Set merge start column */
      stageMergeData[1] = tableData[0].length;

      /** Weights array */
      const stageRoleWeightsList = [];

      const criterias = competencies[i];

      // TODO: Remove this and make everything work fine
      if (!stageRoles.length) {
        // tableData[0].pop();
        tableData[1].push('');
        tableData[2].push('');
        tableData[3].push('');
      }
      for (let j = 0; j < stageRoles.length; j++) {
        const roleMergeData = [2, 0, 2, 0];
        roleMergeData[1] = tableData[1].length + 1;
        const stageRole = stageRoles[j];

        const roleNameLowerCased = stageRole.title.toLowerCase();

        const RoleWithWeight = stageRoleWeights.find(
          (roleWithWeight) =>
            roleWithWeight.role.toLowerCase() === roleNameLowerCased,
        );

        let roleWeight = RoleWithWeight ? RoleWithWeight.weight : 0.0;

        roleWeight = roleWeight || 0;
        stageRoleWeightsList[j] = roleWeight;

        tableData[1].push(
          `${stageRole.title} (${(roleWeight * 100).toFixed(2)}%)`,
        );
        tableData[2].push('');
        if (j !== 0) {
          tableData[0].push('');
        }

        tableData[3].push(stageRole.title);
        const roleIndexStart = tableData[3].length - 1;

        const defaultAligement = {
          alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          },
        };

        const titleCell = {
          ...defaultAligement,
          font: {
            bold: true,
            color: { argb: 'FFFF0000' },
          },
        };

        tableParams.columns[roleIndexStart] = {
          left: true,
          width: 40,
          allCells: {
            border: {
              left: {
                style: j === 0 ? 'double' : 'double',
                color: { argb: 'FF000000' },
              },
            },
          },
          cells: [titleCell, { ...defaultAligement }],
        };

        if (params.rating_stages_grades) {
          /** Add criteria names */
          for (let k = 0; k < criterias.length; k++) {
            const criteria = criterias[k];
            const name = criteria.name;
            const weight = (criteria.weight * 100).toFixed(2);
            tableData[3].push(`${name} (${weight}%)`);
            tableData[0].push('');
            tableData[1].push('');
            tableData[2].push('');
            tableParams.columns[roleIndexStart + k + 1] = {
              width: 25,
            };
          }

          tableData[3].push('Оценка эксперта');
          tableData[0].push('');
          tableData[1].push('');
          tableData[2].push('');
          tableParams.columns[tableData[3].length - 1] = {
            width: 17,
            $except: [1, 2, 3, 4],
            allCells: {
              font: {
                // color: { argb: 'FFFF0000' }
              },
              alignment: {
                wrapText: true,
                vertical: 'middle',
                horizontal: 'center',
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFABC4AA' },
              },
            },
          };
        }

        roleMergeData[3] = tableData[3].length;
        if (params.rating_stages && stageRoles.length === 1) {
          roleMergeData[3]++;
          if (params.rating_stages && shouldAddIntermediateColumn) {
            roleMergeData[3]++;
          }
        }
        tableParams.merges.push(roleMergeData);
      }

      /** Add total result of the stage */
      if (params.rating_stages) {
        for (let t = 0; t < 3; t++) {
          tableData[t].push('');
        }
        tableData[3].push(`Рейтинг за этап ${stage.title}`);
        const finalColumnIndex = tableData[0].length - 1;
        tableParams.columns[finalColumnIndex] = {
          width: 15,
          $except: [1, 2, 3, 4],
          allCells: {
            font: {
              bold: false,
            },
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFEDDBC7' },
            },
          },
        };

        if (shouldAddIntermediateColumn) {
          /** Add intermediate result of the stage */
          tableData[3].push('Промежуточный рейтинг');
          tableParams.columns[tableData[3].length - 1] = {
            width: 15,
          };

          for (let t = 0; t < 3; t++) {
            tableData[t].push('');
          }
        }
      }

      // if (shouldAddRatingDiffColumn) {
      //   /** Add diff rating between experts */
      //   tableData[3].push('Расхождение оценок внутреннего эксперта с внешним');
      //   tableParams.columns[tableData[3].length - 1] = {
      //     width: 15,
      //     $except: [1, 2, 3, 4],
      //     allCells: {
      //       fill: {
      //         type: 'pattern',
      //         pattern: 'solid',
      //         fgColor: { argb: 'FFdecbf5' },
      //       },
      //     },
      //   };
      // const additionalColumns = 4; // Массив с данными для дополнительных ячеек

      // for (let t = 0; t < 3; t++) {
      //   tableData[t].push(''); // Пустая ячейка под заголовком "Расхождение оценок"
      //   for (let i = 0; i < additionalColumns.length; i++) {
      //     tableData[t].push(''); // Пустые ячейки для дополнительных данных
      //   }
      // }
      // }

      if (shouldAddRatingDiffColumn) {
        /** Add diff rating between experts */
        tableData[3].push('Расхождение оценок');
        tableParams.columns[tableData[3].length - 1] = {
          width: 15,
          $except: [1, 2, 3, 4],
          allCells: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFdecbf5' },
            },
          },
        };
        for (let t = 0; t < 3; t++) {
          tableData[t].push('');
        }
        // for (let i = 0; i < 4; i++) {
        //   tableData[3].push(''); // Пустая ячейка под заголовком
        //   for (let t = 0; t < 3; t++) {
        //     tableData[t].push(''); // Пустые ячейки под пустой ячейкой
        //   }
        // }
      }

      if (shouldAddDynamicColumn) {
        tableData[3].push('Динамика между этапами');
        tableParams.columns[tableData[3].length - 1] = {
          width: 15,
          $except: [1, 2, 3, 4],
          allCells: {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF9999C3' },
            },
          },
        };

        for (let t = 0; t < 3; t++) {
          tableData[t].push('');
        }
      }

      stageMergeData[3] = tableData[3].length;
      if (stageRoles.length) tableParams.merges.push(stageMergeData);

      /** Loop through users and create user objects with grades */
      for (let j = 0; j < participants.length; j++) {
        let usersDataObject = usersDataInfo[j];
        // const userBattle = stageBattles[j];
        const user = participants[j];

        const userBattle = stageBattles.find(
          (battle) => battle.player_id === user.id,
        );
        // const user = users.find((u) => u.id === userBattle.player_id);

        let battleGrades = [];
        if (userBattle) {
          battleGrades = await loadBattleGrades(userBattle.id);
        }

        /** Create new user object */
        if (!usersDataObject) {
          usersDataObject = {
            name: `${user.last_name} ${user.first_name} ${user.middle_name}`.trim(),
            chief: user.chief || '',
            region: user.team || '',
            id: user.id,
            stages: [],
            result: 0,
            index: j,
            dynamic: 0,
            prevResult: 0,
            intermediate: 0,
          };
          usersDataInfo[j] = usersDataObject;
        }

        const stageData = {
          title: stage.title,
          id: stage.id,
          roles: [],
          result: 0,
          intermediate: 0,
          diff: 0,
          rolesWeights: [],
          dynamic: 0,
          shouldAddIntermediateColumn,
          shouldAddRatingDiffColumn,
          shouldAddDynamicColumn,
        };

        usersDataObject.stages.push(stageData);

        /** Fill in cells with expert and criteria data */
        for (let k = 0; k < stageRoles.length; k++) {
          const stageRole = stageRoles[k];
          const roleData = {
            roleName: '',
            name: '',
            grades: criterias.map(() => 0),
            gradeWeights: criterias.map((cr) => cr.weight || 0),
            result: 0,
            weight: stageRoleWeightsList[k] || 0,
          };

          let stageRoleUser = null;

          /** Find another user with the expert role */
          if (
            userBattle &&
            userBattle.roles &&
            Array.isArray(userBattle.roles)
          ) {
            const battleRoleResult = userBattle.roles.find(
              (role) => role.stage_roles_id === stageRole.id,
            );

            if (battleRoleResult) {
              const roleIndex = stageRole.index || 0;
              stageRoleUser = battleRoleResult.user_info[roleIndex];
              if (stageRoleUser) {
                roleData.name =
                  `${stageRoleUser?.last_name} ${stageRoleUser?.first_name} ${stageRoleUser?.middle_name}`.trim();
                roleData.roleName = stageRole.title;
              }
            }
          }

          for (let m = 0; m < criterias.length; m++) {
            const criteria = criterias[m];

            if (!stageRoleUser) {
              roleData.grades[m] = 0;
              continue;
            }

            const criteriaGrade = battleGrades.find(
              (grade) =>
                grade.criterion_id === criteria.id &&
                grade.user_id === stageRoleUser.id,
            );
            //TODO:
            // const isNullGrade = battleGrades.forEach((gradeData) => {
            //   if (
            //     gradeData.user_id === stageRoleUser.id &&
            //     gradeData.grade == 0
            //   ) {
            //   }
            // });
            if (criteriaGrade !== undefined) {
              roleData.grades[m] = criteriaGrade.grade || 0;
            }
          }
          let addExpertToExport = false;
          /** Add cells with data to the array */
          if (!roleData.grades.includes(0)) {
            addExpertToExport = true;
          } else {
            addExpertToExport = false;
          }
          stageData.roles.push(roleData);
          //TODO: BattleGrades[].grade - сама оценка. Нужно как-то пройти через весь массив и узнавать
          // того, у кого хоть один 0 - вычеркивать из экспертов. и потом по вот этому всему массиву
          // оствшихся экспертов определять конечные роли

          for (let m = 0; m < roleData.grades.length; m++) {
            const grade = roleData.grades[m] || 0;
            const weight = roleData.gradeWeights[m] || 0;
            roleData.result += grade * weight;
          }

          // let criteriasNumber = criterias.length || 1;
          // roleData.result = roleData.result / criteriasNumber;
          roleData.result = Number(roleData.result.toFixed(2));

          stageData.result += roleData.result * roleData.weight;

          // if (!addExpertToExport) {
          //   stageData.result = 0;
          // }
        }

        stageData.result = Number(stageData.result.toFixed(2));

        const prevResult = usersDataObject.prevResult;
        let newResult = stageData.result;
        /** Find new result scale using min and max values  */

        let dynamicValue = (newResult / prevResult - 1) * 100;

        stageData.dynamic = Number(dynamicValue.toFixed(2));
        usersDataObject.prevResult = newResult;

        usersDataObject.result += stageData.result * stageWeight;
        usersDataObject.intermediate += stageData.result * stageWeight;
        stageData.intermediate = `${usersDataObject.intermediate.toFixed(2)}`;

        const innerExperts = [];
        const outerExperts = [];

        stageData.roles.forEach((role) => {
          if (role.roleName.toLowerCase().includes('внутренний эксперт')) {
            innerExperts.push(role);
          } else if (role.roleName.toLowerCase().includes('внешний эксперт')) {
            outerExperts.push(role);
          }
        });

        if (
          shouldAddRatingDiffColumn &&
          innerExperts.length !== 0 &&
          outerExperts !== 0
        ) {
          const averageInnerExpertGrade = calculateAverageGrades(
            innerExperts,
            criterias.length,
          );
          const averageOuterExpertGrade = calculateAverageGrades(
            outerExperts,
            criterias.length,
          );
          let diff = 0;
          if (averageInnerExpertGrade === 0 || averageOuterExpertGrade === 0) {
            diff = 0;
          } else {
            diff = averageInnerExpertGrade / averageOuterExpertGrade;
            diff -= 1;
            if (isNaN(diff) || !isFinite(diff)) {
              diff = 0;
            }
          }
          diff *= 100;
          stageData.diff = diff;
        }
      }

      stagesAdded++;
    }

    const resultMergeData = [1, 0, 3, 0];

    tableData[0].push('Результат');
    resultMergeData[1] = tableData[0].length;
    /** Offsets start */
    tableData[1].push('');
    tableData[2].push('');
    /** Offsets end */
    const finalColumnIndex = tableData[3].length;

    tableData[3].push(`Итоговый рейтинг за чемпионат`);

    const finalCellData = {
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
      font: {
        size: 14,
      },
    };

    tableParams.columns[finalColumnIndex] = {
      width: 20,
      allCells: {
        border: {
          left: {
            style: 'double',
            color: { argb: 'FF000000' },
          },
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE3F2C1' },
        },
      },
      $except: [1, 2, 3],
      cells: [finalCellData],
    };

    usersDataInfo = usersDataInfo.reduce((acc, item) => {
      const existingItem = acc.find(accItem => accItem.id === item.id);
      if (existingItem) return acc;
      return [...acc, item];
    }, []);

    if (params.rating_position) {
      tableData[3].push(`Место в общем рейтинге`);

      tableParams.columns[tableData[3].length - 1] = {
        width: 15,
        $except: [1, 2, 3, 4],
        allCells: {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFCC8D1' },
          },
        },
      };

      tableData[0].push('');
      tableData[1].push('');
      tableData[2].push('');

      /** Sort users by final result */
      usersDataInfo.sort((a, b) => b.result - a.result);
    }

    resultMergeData[3] = tableData[0].length;
    tableParams.merges.push(resultMergeData);

    /** Add the sorted array with users to the table */
    for (let i = 0; i < usersDataInfo.length; i++) {
      const userInfo = usersDataInfo[i];
      const userRow = [];
      tableBody.push(userRow);
      userRow[0] = `${i + 1}`;
      userRow[1] = userInfo.name;

      if (params.rating_manager) {
        userRow.push(userInfo.chief);
      }

      if (params.rating_region) {
        userRow.push(userInfo.region);
      }

      /** Loop through all stages */
      for (let j = 0; j < userInfo.stages.length; j++) {
        const stage = userInfo.stages[j];

        if (!stage.roles.length) {
          userRow.push('');
        }
        /** Loop through all roles */
        for (let k = 0; k < stage.roles.length; k++) {
          const role = stage.roles[k];
          userRow.push(role.name);
          if (params.rating_stages_grades) {
            /** Loop through all criterias */
            for (let m = 0; m < role.grades.length; m++) {
              const grade = role.grades[m] || 0;
              userRow.push(grade);
            }
            /** Add total user rating by expert */
            userRow.push(role.result);
          }
        }
        if (params.rating_stages) {
          /** Add total user rating by stage */
          userRow.push(stage.result);
          /** Add intermediate result */
          if (stage.shouldAddIntermediateColumn) {
            userRow.push(stage.intermediate);
          }
        }
        /** Add rating diff */
        if (stage.shouldAddRatingDiffColumn) {
          // for (let i = 0; i < stage.diff.length; i++) {
          //   userRow.push(`${stage.diff[i].toFixed(2)}%`);
          // }
          userRow.push(`${stage.diff.toFixed(2)}%`);
        }
        /** Add stage dynamic */
        if (stage.shouldAddDynamicColumn) {
          userRow.push(`${stage.dynamic}%`);
        }
      }

      /** Add 'Final Result' cell */
      userInfo.result = Number(userInfo.result.toFixed(2));
      userRow.push(userInfo.result);
      if (params.rating_position) {
        /** Add place in rating cell */
        userRow.push(`${i + 1}`);
      }
    }

    tableData.push(...tableBody);

    exportToXLSX({
      tableName: `championship`,
      tableData: tableData,
      params: tableParams,
    });
  };

  exportUsersTable();
}
