import { usePickerState } from '@material-ui/pickers';
import CompetenceAPI from '../network/CompetenceAPI';
import {
  getBattleFull,
  getBattleGrades,
  getBattleList,
  getChamp,
  getChampUserList,
  getStageList,
  getStageRoleList,
} from '../network/fetchApi';

export const loadChampData = (id) => {
    return new Promise((resolve) => {
      getChamp({ id }, (champJSON) => {
        if (champJSON.status !== 200) resolve({});
        const champ = champJSON.body;
        resolve(champ);
      });
    });
};

export const loadChampStages = (id) => {
    const stagesParams = {
      filters: [
        {
          field: 'championship_id',
          equal: id,
        },
      ],
      limit: 100,
    };

    return new Promise((resolve) => {
      getStageList(stagesParams, (stagesJSON) => {
        if (stagesJSON.status !== 200) resolve([]);
        let stages = stagesJSON.body.items;
        stages.sort((a, b) => {
          return new Date(a.time_start) - new Date(b.time_start);
        });
    resolve(stages);
    });
  });
};

const loadChampUsersPartial = (id, offset = 0) => {
    return new Promise((resolve) => {
        getChampUserList({ id}, { limit: 100, offset }, (responseJson) => {
            if (responseJson.status !== 200) resolve({ hits: 0, items: [] });
            resolve(responseJson.body);
        });
    });
};

export const loadChampUsers = async (id) => {
    const users = [];
    const firstCallResult = await loadChampUsersPartial(id);
    users.push(...firstCallResult.items);

    const callsLeft = Math.ceil(firstCallResult.hits / 100 - 1);

    for (let i = 1; i <= callsLeft; ++i) {
        const nextCallResult = await loadChampUsersPartial(id, 100 * i);
        users.push(...nextCallResult.items);
    }

    // return users.filter(user => user.is_player);
    return users;
};

export const loadStageRoles = (stageId) => {
    return new Promise((resolve) => {
      getStageRoleList({ id: stageId }, (responseJson) => {
        if (responseJson.status !== 200) resolve([]);
        let roles = responseJson.body.items;
        roles = roles.filter(
          (role) => role.code_name === '' && !!role.rights.give_grades,
        );
        resolve(roles);
      });
    });
};

const loadBattlesListPartial = (stageId, offset = 0) => {
  return new Promise((resolve) => {
    const params = {
      filters: [
        {
          field: 'stage_id',
          equal: stageId,
        }
      ],
      limit: 100
    };

    getBattleList(params, (responseJson) => {
      if (responseJson.status !== 200) resolve({ hits: 0, items: []});
      resolve(responseJson.body);
    });
  })
}

export const loadBattlesList = async (stageId) => {
  const battles = [];
  const firstBattleListCallResult = await loadBattlesListPartial(stageId);
  battles.push(...firstBattleListCallResult.items);

  const callsLeft = Math.ceil(firstBattleListCallResult.hits / 100 - 1);

  for (let i = 1; i <= callsLeft; ++i) {
    const nextBattleListCallResult = await loadBattlesListPartial(stageId, 100 * i);
    battles.push(...nextBattleListCallResult.items);
  }

  return battles;
};

export const loadCompetencies = async (stageId) => {
    const params = {
      filters: [
        {
          field: 'stage_id',
          equal: stageId,
        },
      ],
      limit: 100,
    };

    const data = await CompetenceAPI.getCompetencies(params);
    if (data.status !== 200) return [];
    const body = data.body;
    if (!body || !body.length) return [];

    return [
      body[0].criteria,
      body[0].roles_with_weights,
      { min: body[0].min_grade, max: body[0].max_grade },
    ];
};

export const loadBattleInfo = (battleID) => {
    const params = {
      order_by: [
        {
          field: 'time',
          order: 'asc',
        },
      ],
      limit: 100,
    };

    return new Promise((resolve) => {
      getBattleFull({ id: battleID }, params, (responseJson) => {
        if (responseJson.status !== 200) resolve({});
        let battle = responseJson.body;
        resolve(battle);
      });
    });
};

export const loadBattleGrades = (battleID) => {
    return new Promise((resolve) => {
      getBattleGrades(battleID, (responseJson) => {
        if (responseJson.status !== 200) resolve([]);
        let grades = responseJson.body.battle_grades || [];
        resolve(grades);
      });
    });
};